.progressbar {
    background-color: white;
    border-radius: 13px;
    align-content: center;
    text-align: center;
    padding: 3px;
    display: inline;
}

.progressbar> .component {
    height: 27px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    float: left;
    font-size: 11pt;
    width: 33%;
}

.progressbar> .completed{
    background-color: #3d027b;
    color: white;
}

.progressbar> .uncompleted{
    background-color: #e6e6fa;
    color: black;
}