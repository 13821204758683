.after-landing-page-shape {
  height: 8%!important;
}
.toast-msg-box{
  display: block;
  width: 0px;
  height: 110px;
  float: right;
}

.fully-active-alert {
  opacity: 1;
  width: 100%;
  transition-property: opacity, width;
  transition-duration: 3s, 1s;
  transition-timing-function: ease-in;
}
.transitioning-alert {
  opacity: 0.1;
  position: relative;
}




.invalid-input-message {
  color: red;
  text-align: center;
}

.succeed-signup-message {
  text-align: center;
  color: green;
}

.failed-signin-message {
  text-align: center;
  color: red;
}

.toast-messages-container {
  z-index: 1050;
  /*left:72%;*/
  background-color: transparent;
}

.toast-error {
  background-color: #FA2A55;
  color: white;
}

.toast-success {
  background-color: #3CB371;
  color: white;
}

.toast-warning {
  background-color: #DEB887;
  color: white;
}