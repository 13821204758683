.section-title {
  margin-bottom: 15px;
}

datalist {
  position: absolute;
  max-height: 20em;
  border: 0 none;
  overflow-x: hidden;
  overflow-y: auto;
  border-bottom: 0.5mm solid #edf2f9;
  border-left: 0.5mm solid #edf2f9;
  border-right: 0.5mm solid #edf2f9;
}

datalist option {
  font-size: 1.1em;
  padding: 0.5em 1em;
  background-color: #F8F8F8;
  cursor: pointer;
}

/* option active styles */
datalist option:hover, datalist option:focus {
  color: #fff;
  background-color: #036;
  outline: 0 none;
}

