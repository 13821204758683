.upload-image-preview {
  width: 100%;
  height: 100%;
}
.upload-image-preview-container {
  float: left;
  width: 150pt;
  height: 100pt;
  margin-left: 10pt;
  margin-right: 10pt;
  margin-bottom: 50pt
}